/* HeroCarousel.css */
.carousel-root {
  position: relative;
}

.carousel-root .control-prev,
.carousel-root .control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-root .control-prev:hover,
.carousel-root .control-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel-root .control-prev {
  left: 20px;
}

.carousel-root .control-next {
  right: 20px;
}
