/* Footer.css */
.footer {
  background-color: #0136a0;
  color: #fff;
  padding: 40px 20px;
}

.footer .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer .col-md-3 {
  flex: 1 0 25%;
  margin-bottom: 20px;
  padding: 0 10px;
}

.footer .footer-logo {
  text-align: center;
  margin-bottom: 20px;
}

.footer .footer-logo img {
  max-width: 200px;
}

.footer .footer-links {
  margin-bottom: 20px;
}

.footer .footer-links h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
}

.footer .footer-links li {
  margin-bottom: 5px;
}

.footer .footer-links a {
  color: #e5e5e5;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer .footer-links a:hover {
  color: #007bff;
}

.footer .footer-bottom {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .footer .col-md-3 {
    flex: 1 0 50%;
    padding: 0 20px;
    text-align: left;
  }
}

@media screen and (max-width: 480px) {
  .footer .col-md-3 {
    flex: 1 0 100%;
    padding: 0 20px;
    text-align: left;
  }
}
