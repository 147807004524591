.categories-banner {
  position: sticky !important;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  gap: 4px;
  background-color: #242424;
  padding: 1px 75px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.category-link {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 20px;
  position: relative;
}

.category-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2.5px;
  background: #fda512;
  border-radius: 15px;
  transition: width 0.3s;
  position: absolute;
  left: 0;
  bottom: -1px;
}

.category-link:hover::after {
  width: 100%;
}

@media (max-width: 500px) {
  .categories-banner {
    display: none;
  }
}
